import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import Playground from '../../../src/Playground';
import { UiViewport, Breakpoints, ThemeContext } from '../src/';
import * as packageJson from '../package.json';
import { UiCard } from '@uireact/card';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiviewport"
    }}>{`UiViewport`}</h1>
    <hr></hr>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/foundation/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`This component make it easy to render/remove components from the DOM based on the breakpoint.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation-"
    }}>{`Installation 🧰`}</h2>
    <p>{`This component is exported as part of @uireact/foundation`}</p>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation`}</p>
    </blockquote>
    <h2 {...{
      "id": "usages-️"
    }}>{`Usages 🛠️`}</h2>
    <h3 {...{
      "id": "specific-breakpoint"
    }}>{`Specific breakpoint`}</h3>
    <Playground __position={1} __code={'<UiViewport criteria={Breakpoints.XLARGE}>\n  <UiCard>Hey, I\'m in XLarge!</UiCard>\n</UiViewport>\n<UiViewport criteria={Breakpoints.LARGE}>\n  <UiCard>Hey, I\'m in Large!</UiCard>\n</UiViewport>\n<UiViewport criteria={Breakpoints.MEDIUM}>\n  <UiCard>I appear in Medium!!</UiCard>\n</UiViewport>\n<UiViewport criteria={Breakpoints.SMALL}>\n  <UiCard>And I\'m in Small!</UiCard>\n</UiViewport>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      UiViewport,
      Breakpoints,
      ThemeContext,
      packageJson,
      UiCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiViewport criteria={Breakpoints.XLARGE} mdxType="UiViewport">
    <UiCard mdxType="UiCard">Hey, I'm in XLarge!</UiCard>
  </UiViewport>
  <UiViewport criteria={Breakpoints.LARGE} mdxType="UiViewport">
    <UiCard mdxType="UiCard">Hey, I'm in Large!</UiCard>
  </UiViewport>
  <UiViewport criteria={Breakpoints.MEDIUM} mdxType="UiViewport">
    <UiCard mdxType="UiCard">I appear in Medium!!</UiCard>
  </UiViewport>
  <UiViewport criteria={Breakpoints.SMALL} mdxType="UiViewport">
    <UiCard mdxType="UiCard">And I'm in Small!</UiCard>
  </UiViewport>
    </Playground>
    <p>{`The `}<a parentName="p" {...{
        "href": "./packages-foundation-docs-breakpoints#definition"
      }}>{`Breakpoints`}</a>{` enum is used to set up one specific breakpoint where we want the UiViewport to render something.`}</p>
    <h3 {...{
      "id": "multiple-breakpoints"
    }}>{`Multiple breakpoints`}</h3>
    <Playground __position={2} __code={'<UiViewport criteria=\"m|l|xl\">\n  <UiCard>Hey, I appear in medium, large and xlarge!!</UiCard>\n</UiViewport>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      UiViewport,
      Breakpoints,
      ThemeContext,
      packageJson,
      UiCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiViewport criteria="m|l|xl" mdxType="UiViewport">
    <UiCard mdxType="UiCard">Hey, I appear in medium, large and xlarge!!</UiCard>
  </UiViewport>
    </Playground>
    <p>{`Multiple breakpoints are supported as prop for the UiViewport when used the string criteria. The whole set of combinations is defined in the `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/responsive/viewport.tsx#L6"
      }}>{`BreakpointsString`}</a>{` type.`}</p>
    <h2 {...{
      "id": "props-️"
    }}>{`Props 🎛️`}</h2>
    <Props of={UiViewport} mdxType="Props" />
    <h2 {...{
      "id": "details-"
    }}>{`Details 🤓`}</h2>
    <p>{`For specific information about the breakpoints definitions you can look at this `}<a parentName="p" {...{
        "href": "./packages-foundation-docs-breakpoints"
      }}>{`doc page`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      